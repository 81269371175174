const API_URL = process.env.VUE_APP_API_URL
const API_VERSION = process.env.VUE_APP_API_VERSION

const API_ROUTES = {
	company: {
		get: `${API_URL}/${API_VERSION}/company`,
		save: `${API_URL}/${API_VERSION}/company`,
		delete: `${API_URL}/${API_VERSION}/company`,
		update: `${API_URL}/${API_VERSION}/company`,

	},
	brand: {
		get: `${API_URL}/${API_VERSION}/company/:customer_id/brands`,
		find: `${API_URL}/${API_VERSION}/company/:customer_id/brand/:id`,
		save: `${API_URL}/${API_VERSION}/company/:customer_id/brand/:id`,
		delete: `${API_URL}/${API_VERSION}/company/:customer_id/brand/:id`,
		update: `${API_URL}/${API_VERSION}/company/:customer_id/brand/:id`,
	},
	country: {
		get: `${API_URL}/${API_VERSION}/countries`,
	},
	departament: {
		get: `${API_URL}/${API_VERSION}/departments`,
	},
	city: {
		get: `${API_URL}/${API_VERSION}/cities`,
	},
	service: {
		get: `${API_URL}/${API_VERSION}/company/brand/:brand_id/service`,
		save: `${API_URL}/${API_VERSION}/company/brand/:brand_id/service/:id`,
		delete: `${API_URL}/${API_VERSION}/company/brand/:brand_id/service/:id`,
		update: `${API_URL}/${API_VERSION}/company/brand/:brand_id/service/:id`,
	},
	area: {
		get: `${API_URL}/${API_VERSION}/company/filters/area`,
		save: `${API_URL}/${API_VERSION}/company/service/:service_id/area/:id`,
		delete: `${API_URL}/${API_VERSION}/company/service/:service_id/area/:id`,
		update: `${API_URL}/${API_VERSION}/company/service/:service_id/area/:id`,
	},
	subarea: {
		get: `${API_URL}/${API_VERSION}/company/area/:area_id/subarea`,
		save: `${API_URL}/${API_VERSION}/company/area/:area_id/subarea/:id`,
		delete: `${API_URL}/${API_VERSION}/company/area/:area_id/subarea/:id`,
		update: `${API_URL}/${API_VERSION}/company/area/:area_id/subarea/:id`,
	},
	location: {
		get: `${API_URL}/${API_VERSION}/company/subarea/:subarea_id/locations`,
		save: `${API_URL}/${API_VERSION}/company/subarea/:subarea_id/location/:id`,
		delete: `${API_URL}/${API_VERSION}/company/subarea/:subarea_id/location/:id`,
		update: `${API_URL}/${API_VERSION}/company/subarea/:subarea_id/location/:id`,
	},
	device: {
		get: `${API_URL}/${API_VERSION}/brand/:id/device`,
		save: `${API_URL}/${API_VERSION}/device`,
		delete: `${API_URL}/${API_VERSION}/device`,
		update: `${API_URL}/${API_VERSION}/device`,
	},
	location_type: {
		get: `${API_URL}/${API_VERSION}/location-types`,
	},
	device_type: {
		get: `${API_URL}/${API_VERSION}/device-types`,
	},
}

export { API_ROUTES }
