import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll(subarea_id) {
		const params = {
			order: 'desc',
			order_by: 'name',
			offset: 0,
			limit: 0,
			/* 'filters[0][field]': 'subarea_id',
			'filters[0][operator]': '=',
			'filters[0][value]': subarea_id, */
		}
		const url = API_ROUTES.location.get
			.replace(':subarea_id', subarea_id)
		const { data } = await useJwt.get(url, params)

		return data
	},
	async find(id) {
		const { data } = await useJwt.get(`${API_ROUTES.location.find}/${id}`)

		return data
	},
	async save(subarea_id, id, data) {
		const url = API_ROUTES.location.save
			.replace(':subarea_id', subarea_id)
			.replace(':id', id)
		return useJwt.put(url, data)
	},
	delete(subarea_id, id) {
		const url = API_ROUTES.location.delete
			.replace(':subarea_id', subarea_id)
			.replace(':id', id)
		return useJwt.delete(url)
	},
	update(subarea_id, id, data) {
		const url = API_ROUTES.location.update
			.replace(':subarea_id', subarea_id)
			.replace(':id', id)
		return useJwt.patch(url, data)
	},
	async getLocationTypeAll() {
		const { data } = await useJwt.get(`${API_ROUTES.location_type.get}`)

		return data
	},
}
